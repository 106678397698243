@import '/src/apps/web/styles/vendors/material/material-colors';

.text-primary {
  color: $app-primary-base !important;
}

.text-primary-light {
  color: $app-primary-light;
}

.text-accent-base {
  color: $app-accent-base;
}

.font-medium {
  font-weight: 500;
}
