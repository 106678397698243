@use '@angular/material' as mat;
@include mat.core();

@import 'material-typography';
@import 'material-colors';

// $my-primary: mat.define-palette(mat.$indigo-palette, 500);
// $my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-theme-primary,
      accent: $app-theme-accent,
      warn: $app-theme-warn,
    ),
    density: 0,
    typography: $app-typography-config,
  )
);

@include mat.all-component-typographies($app-typography-config);
@include mat.all-component-themes($app-theme);

// UTILS CLASSES
.border {
  &-black {
    border-color: $app-black !important;
  }
}

.text {
  &-black {
    color: $app-black !important;
  }
  &-primary-content {
    color: $app-white !important;
  }
}

.bg {
  &-primary-light {
    background-color: $app-primary-light !important;
  }
  &-primary-medium {
    background-color: $app-primary-medium !important;
  }
  &-accent-light {
    background-color: $app-accent-light !important;
  }
}
