@use '@angular/material' as mat;
@import 'mat-palette-generator.scss';

$primary-color: #202020;
$accent-color: #1875f0;

$primary-color-base: 900;
$primary-color-medium: 300;
$primary-color-light: 200;
$primary-color-dark: 800;
$primary-color-super-light: 100;
$app-theme-primary: mat.define-palette(
  mat.$gray-palette,
  $primary-color-base,
  $primary-color-light,
  $primary-color-dark
);

$accent-color-base: 600;
$accent-color-light: 50;
$accent-color-dark: 900;
$app-theme-accent: mat.define-palette(
  createPalette($accent-color),
  $accent-color-base,
  $accent-color-light,
  $accent-color-dark
);

$warn-color-base: 500;
$warn-color-light: 50;
$warn-color-dark: 900;
$app-theme-warn: mat.define-palette(mat.$red-palette, $warn-color-base, $warn-color-light, $warn-color-dark);

$success-color-base: 500;
$success-color-light: 50;
$success-color-dark: 900;
$app-theme-success: mat.define-palette(
  mat.$light-green-palette,
  $success-color-base,
  $success-color-light,
  $success-color-dark
);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$app-primary-base: mat.get-color-from-palette($app-theme-primary, $primary-color-base);
$app-primary-light: mat.get-color-from-palette($app-theme-primary, $primary-color-light);
$app-primary-dark: mat.get-color-from-palette($app-theme-primary, $primary-color-dark);
$app-primary-medium: mat.get-color-from-palette($app-theme-primary, $primary-color-medium);
$app-primary-super-light: mat.get-color-from-palette($app-theme-primary, $primary-color-super-light);

$app-accent-base: mat.get-color-from-palette($app-theme-accent, $accent-color-base);
$app-accent-light: mat.get-color-from-palette($app-theme-accent, $accent-color-light);
$app-accent-dark: mat.get-color-from-palette($app-theme-accent, $accent-color-dark);

$app-warn-base: mat.get-color-from-palette($app-theme-warn, $warn-color-base);
$app-warn-light: mat.get-color-from-palette($app-theme-warn, $warn-color-light);
$app-warn-dark: mat.get-color-from-palette($app-theme-warn, $warn-color-dark);

$app-success-base: mat.get-color-from-palette($app-theme-success, $success-color-base);
$app-success-light: mat.get-color-from-palette($app-theme-success, $success-color-light);
$app-success-dark: mat.get-color-from-palette($app-theme-success, $success-color-dark);

$app-white: rgb(255, 255, 255);

$app-black: rgb(0, 0, 0);

$app-grey: rgb(0, 0, 0, 0.6);
$app-dark-gray: rgba(0, 0, 0, 0.87);

$app-background: $app-primary-super-light;

$app-warning-box-background: #ff8d8a;

$app-info-box-background: #95b9f1;

$app-green: #3f9144;
$app-light-blue: #f3f8ff;
$app-link-blue: rgba(51, 101, 246, 1);
$app-light-yellow: rgba(255, 242, 213, 1);
$app-light-gray: rgba(204, 206, 212, 1);
$app-gray: #c3c3c3;
$app-dark-grey: #585858;
