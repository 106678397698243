@import '/src/apps/web/styles/vendors/bootstrap/bootstrap-shared';
@import '/src/apps/web/styles/vendors/material/material-colors';
@import '/src/apps/web/styles/settings/settings.scss';

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: #ffffff) {
  word-wrap: break-word;
  overflow: hidden;
  max-height: $lineHeight * $lineCount;
  line-height: $lineHeight;
  padding-right: 20px !important;
  position: relative;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: ' ';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor !important;
  }
}

.gap {
  &-0 {
    gap: 0 !important;
  }
  &-1 {
    gap: ($spacer * 0.25) !important;
  }
  &-2 {
    gap: ($spacer * 0.5) !important;
  }
  &-3 {
    gap: $spacer !important;
  }
}

.border {
  &-black {
    border-color: $app-black !important;
  }
}

.text {
  &-black {
    color: $app-black !important;
  }
}

.bg {
  &-primary-light {
    background-color: $app-primary-light !important;
  }
  &-primary-medium {
    background-color: $app-primary-medium !important;
  }
  &-accent-light {
    background-color: $app-accent-light !important;
  }
}

.mat-toolbar-bottom-padding {
  padding-bottom: calc($mat-toolbar-height-mobile - $spacer);
  @include media-breakpoint-up(sm) {
    padding-bottom: calc($mat-toolbar-height-desktop - $spacer);
  }
}

.debug {
  border: 1px solid red;
}

.debug2 {
  border: 1px solid green;
}
