button {
  text-transform: uppercase;

  &[mat-fab].small {
    height: 30px !important;
    font-size: 10px !important;
    max-width: 135px !important;
    mat-icon {
      height: 10px;
      font-size: 10px !important;
      margin-right: 0px !important;
      align-content: center !important;
    }
  }
}
