@import '/src/apps/web/styles/vendors/material/material-colors';

.sidebar,
.sidenav {
  @include media-breakpoint-up(sm) {
    max-width: $sidenav-sm-desktop-width;
    width: $sidenav-sm-desktop-width;
  }
  width: 100%;
  background-color: $app-primary-base;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  a {
    text-align: left;
    justify-content: flex-start;
    width: 100%;
    background-color: $app-primary-base;
    box-shadow: none;
  }

  &-item {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
  &-item.active {
    a {
      background-color: $app-dark-grey;
    }
  }

  &-subitem-dot {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: -8px;
    translate: 0px -4px;
  }

  mat-accordion {
    display: block;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
  }
  mat-expansion-panel {
    background: transparent !important;
    color: $app-primary-light !important;
    box-shadow: none !important;
    --mat-expansion-header-indicator-color: $app-primary-light;
    --mat-expansion-container-background-color: $app-primary-base;
  }
  .mat-expansion-panel-header-title {
    color: $app-primary-light !important;
  }
  .mat-expansion-panel-body {
    padding: 0px;
  }
}
